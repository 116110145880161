import React from 'react';
import { Link } from 'react-router-dom';
import './home.css';

const Home = () => {
  return (
    <div>
      <div className="home-link">
        <Link to="/offices" className="text-link">
          Standalone Eagle Tracker for Morgan Stanley Offices
        </Link>
      </div>
      <div className="home-link">
        <Link to="/offices-vertical" className="text-link">
          Standalone Eagle Tracker for Morgan Stanley Offices Vertical
        </Link>
      </div>
      <div className="home-link">
        <Link to="/grove" className="text-link">
          Slider in the Grove
        </Link>
      </div>
      <div className="home-link">
        <Link to="/tent" className="text-link">
          Standalone Eagle Tracker in the Tent
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-17" className="text-link">
          Hospitality Tent 17
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-18" className="text-link">
          Hospitality Tent 18
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-17-thursday" className="text-link">
          Hospitality Tent 17 - Thursday Only
        </Link>
      </div>
      <div className="home-link">
        <Link to="/hospitality-18-thursday" className="text-link">
          Hospitality Tent 18 - Thursday Only
        </Link>
      </div>
    </div>
  );
};

export default Home;
