import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import ImageSlide from '../../components/slide/ImageSlide';
import VideoSlide from '../../components/slide/VideoSlide';
import EagleTrackerSlide from '../../components/slide/EagleTrackerSlide';
import CircularWithValueLabel from '../../components/circular-progress';
import { useEagleNumberCountingForGrove } from '../../hooks';

const Hospitality18ThursdayPage = (props) => {
  const { eaglesCunt } = props;

  const [isEagleTrackerLoading, setIsEagleTrackerLoading] = useState(true);
  const [isFirstSlideLoading, setIsFirstSlideLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [isSelected, setIsSelected] = useState(false);

  const { firstDigit, secondDigit } = useEagleNumberCountingForGrove(
    eaglesCunt,
    isSelected,
  );

  useEffect(() => {
    if (!isEagleTrackerLoading && !isFirstSlideLoading) {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isEagleTrackerLoading, isFirstSlideLoading]);

  useEffect(() => {
    if (isLoading) return;

    const timeoutId = setTimeout(() => {
      setIndex((index) => {
        let nextIndex = 0;
        if (index < Object.keys(slideIntervals).length) nextIndex = index + 1;
        console.log(`Active slide: ${nextIndex}`);
        return nextIndex;
      });
    }, slideIntervals[index]);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [index, isLoading]);

  useEffect(() => {
    if (isLoading) return;

    setIsSelected(index === 13);
  }, [index, isLoading]);

  const slideIntervals = {
    0: 1000, // Black Image
    1: 65000, // Grit to Vision Video
    2: 50, // Black Image
    3: 30000, // Justin Rose still image
    4: 50, // Black Image
    5: 35000, // CE Video
    6: 50, // Black Image
    7: 30000, // Tour Though Time Hole 9 still image
    8: 50, // Black Image
    9: 95000, // Anthem Video
    10: 50, // Black Image
    11: 30000, // Tour Through Time Hole 18 still image
    12: 50, // Black Image
    13: 30000, // EagleTracker
    14: 50, // Black Image
    15: 30000, // Jack Appearance still image
    16: 50, // Black Image
    17: 30000, // Xander Appearance still image
  };

  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  return (
    <div className="App">
      {isLoading ? <CircularWithValueLabel /> : null}
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        transitionTime={3}
        stopOnHover={false}
        swipeable={false}
        useKeyboardArrows={false}
        showIndicators={false}
        autoPlay={false}
        renderItem={customRenderItem}
        selectedItem={index}
      >
        <ImageSlide key="image-10" url="/black.jpg" />
        <VideoSlide
          playing={index === 1}
          key="vide-1"
          setIsLoading={setIsFirstSlideLoading}
          url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/grit.mp4"
        />
        <ImageSlide key="image-11" url="/black.jpg" />
        <ImageSlide key="image-1" url="/horizontal/grove_static_slide_2.jpg" />
        <ImageSlide key="image-12" url="/black.jpg" />
        <VideoSlide
          playing={index === 5}
          key="vide-2"
          url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/ce.mp4"
        />
        <ImageSlide key="image-13" url="/black.jpg" />
        <ImageSlide key="image-2" url="/horizontal/hospitality_9.jpg" />
        <ImageSlide key="image-14" url="/black.jpg" />
        <VideoSlide
          playing={index === 9}
          key="vide-3"
          url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/anthem.mp4"
        />
        <ImageSlide key="image-17" url="/black.jpg" />
        <ImageSlide key="image-2" url="/horizontal/hospitality_18.jpg" />
        <ImageSlide key="image-14" url="/black.jpg" />
        <EagleTrackerSlide
          {...props}
          isSelected={index === 13}
          firstDigit={firstDigit}
          secondDigit={secondDigit}
          setIsLoading={setIsEagleTrackerLoading}
        />
        <ImageSlide key="image-14" url="/black.jpg" />
        <ImageSlide key="image-4" url="/horizontal/jack.jpg" />
        <ImageSlide key="image-15" url="/black.jpg" />
        <ImageSlide key="image-5" url="/horizontal/xander.jpg" />
      </Carousel>
    </div>
  );
};

export default Hospitality18ThursdayPage;
