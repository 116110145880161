import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function CircularWithValueLabel() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(1, 1, 1, 1)',
        zIndex: 9999,
      }}
    >
      <CircularProgress />
    </div>
  );
}
