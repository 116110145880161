import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './slide.css';
import EagleNumber from '../eagle-number';

const styles = {
  slide: {
    width: '1920px',
    height: '1080px',
  },
  countWithUs: {
    position: 'absolute',
    top: '140px',
    left: '0px',
  },
  countWithUsText: {
    position: 'absolute',
    top: '290px',
    left: '0px',
  },
  eagleTrackerText: {
    position: 'absolute',
    top: '160px',
    left: '-50px',
  },
  logo: {
    position: 'absolute',
    top: '900px',
    left: '0px',
  },
  eagleNumber: {
    firstDigit: {
      position: 'absolute',
      top: '15px',
      left: '1170px',
    },
    secondDigit: {
      position: 'absolute',
      top: '15px',
      left: '1420px',
    },
    digitSize: {
      width: '530px',
      height: '530px',
    },
  },
};

const EagleTrackerSlide = (props) => {
  const { eaglesCunt, setIsLoading = () => {}, isSelected = true } = props;
  let player;

  const [playing, setPlaying] = useState(false);
  const [isLoadingDigits, setIsLoadingDigits] = useState(true);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);

  useEffect(() => {
    if (!isSelected) {
      setPlaying(false);
      player?.seekTo(0, 'seconds');
      return;
    }
    const timeoutlId = setTimeout(() => {
      setPlaying(true);
    }, 2000);
    return () => {
      clearTimeout(timeoutlId);
    };
  }, [isSelected, eaglesCunt]);

  useEffect(() => {
    if (isLoadingDigits || isLoadingVideo) return;
    setIsLoading(false);
  }, [isLoadingDigits, isLoadingVideo]);

  return (
    <div key={101}>
      <div
        style={{
          ...styles.countWithUs,
        }}
      >
        <img
          src={'/horizontal/tracker_1_header_horizontal.png'}
          alt={'text_headline'}
        />
      </div>
      <div
        style={{
          ...styles.countWithUsText,
        }}
      >
        <img
          src={'/horizontal/tracker_1_content_horizontal.png'}
          alt={'text_headline'}
        />
        <div
          style={{
            ...styles.eagleTrackerText,
          }}
        >
          <img
            src={'/horizontal/tracker_1_label_horizontal.png'}
            alt={'text_headline'}
          />
        </div>
      </div>
      <div
        style={{
          ...styles.logo,
        }}
      >
        <img
          src={'/horizontal/tracker_1_footer_horizontal.png'}
          alt={'text_headline'}
        />
      </div>
      <ReactPlayer
        ref={(p) => {
          player = p;
        }}
        className="eagle-video"
        url="/horizontal/0070-0200_horizontal.mp4"
        muted={true}
        playing={playing}
        onEnded={() => setPlaying(false)}
        width={1920}
        height={1080}
        onReady={() => setIsLoadingVideo(false)}
      />
      <div>
        <EagleNumber
          {...props}
          setIsLoadingDigits={setIsLoadingDigits}
          styles={styles.eagleNumber}
        />
      </div>
    </div>
  );
};

export default EagleTrackerSlide;
