import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './slide.css';
import EagleNumber from '../eagle-number';

const styles = {
  slide: {
    width: '1080px',
    height: '1920px',
  },
  countWithUs: {
    position: 'absolute',
    top: '240px',
    left: '0px',
  },
  eagleTrackerText: {
    position: 'absolute',
    top: '240px',
    left: '0px',
  },
  countWithUsText: {
    position: 'absolute',
    top: '520px',
    left: '0px',
  },
  logo: {
    position: 'absolute',
    top: '1650px',
    left: '0px',
  },
  countWithUsSize: {
    width: '927px',
    height: '137px',
  },
  eagleNumber: {
    firstDigit: {
      position: 'absolute',
      top: '10px',
      left: '668px',
    },
    secondDigit: {
      position: 'absolute',
      top: '10px',
      left: '798px',
    },
    digitSize: {
      width: '285px',
      height: '285px',
    },
  },
};

const EagleTrackerVerticalSlide = (props) => {
  const { eaglesCunt, setIsLoading, isSelected = true } = props;
  let player;

  const [playing, setPlaying] = useState(false);
  const [isLoadingDigits, setIsLoadingDigits] = useState(true);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);

  useEffect(() => {
    if (!isSelected) {
      setPlaying(false);
      player?.seekTo(0, 'seconds');
      return;
    }
    const timeoutlId = setTimeout(() => {
      setPlaying(true);
    }, 500);
    return () => {
      clearTimeout(timeoutlId);
    };
  }, [isSelected, eaglesCunt]);

  useEffect(() => {
    if (isLoadingDigits || isLoadingVideo) return;
    setIsLoading(false);
  }, [isLoadingDigits, isLoadingVideo]);

  return (
    <div key={101}>
      <div
        style={{
          ...styles.eagleTrackerText,
        }}
      >
        <img
          src={'/vertical/tracker_1_label_vertical.png'}
          alt={'text_headline'}
        />
      </div>
      <div
        style={{
          ...styles.countWithUs,
        }}
      >
        <img
          src={'/vertical/tracker_1_2_header_vertical.png'}
          alt={'text_headline'}
        />
      </div>
      <div
        style={{
          ...styles.countWithUsText,
        }}
      >
        <img
          src={'/vertical/tracker_1_content_vertical.png'}
          alt={'text_headline'}
        />
      </div>
      <div
        style={{
          ...styles.logo,
        }}
      >
        <img
          src={'/vertical/tracker_1_2_footer_vertical.png'}
          alt={'text_headline'}
        />
      </div>
      <ReactPlayer
        ref={(p) => {
          player = p;
        }}
        className="eagle-video"
        url="/vertical/0070-0200_vertical.mp4"
        muted={true}
        playing={playing}
        onEnded={() => setPlaying(false)}
        width={styles.slide.width}
        height={styles.slide.height}
        onReady={() => setIsLoadingVideo(false)}
      />

      <div>
        <EagleNumber
          {...props}
          setIsLoadingDigits={setIsLoadingDigits}
          styles={styles.eagleNumber}
        />
      </div>
    </div>
  );
};

export default EagleTrackerVerticalSlide;
