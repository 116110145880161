import React from 'react';
import EagleNumber from '../eagle-number';
import './slide.css';

const styles = {
  slide: {
    width: '1920px',
    height: '1080px',
  },
  eagleNumber: {
    firstDigit: {
      position: 'absolute',
      top: '10px',
      left: '1492px',
    },
    secondDigit: {
      position: 'absolute',
      top: '10px',
      left: '1624px',
    },
    digitSize: {
      width: '295px',
      height: '295px',
    },
  },
};

const EagleTrackerBlueSlide = (props) => {
  return (
    <div key={101}>
      <img
        width={styles.slide.width}
        height={styles.slide.height}
        src="/horizontal/tracker_2_background_horizontal.png"
        alt="1"
      />
      <div>
        <EagleNumber {...props} styles={styles.eagleNumber} />
      </div>
    </div>
  );
};

export default EagleTrackerBlueSlide;
