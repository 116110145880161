import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import EagleTrackerVerticalSlide from '../../components/slide/EagleTrackerVerticalSlide';
import EagleTrackerBlueVerticalSlide from '../../components/slide/EagleTrackerBlueVerticalSlide';
import EagleTrackerSecondVerticalSlide from '../../components/slide/EagleTrackerSecondVerticalSlide';
import ImageSlide from '../../components/slide/ImageSlide';
import { useEagleNumberCountingForTent } from '../../hooks';
import CircularWithValueLabel from '../../components/circular-progress';
import './tent.css';

const TentPage = (props) => {
  const { eaglesCunt } = props;
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTracker1, setIsLoadingTracker1] = useState(true);
  const [isLoadingTracker2, setIsLoadingTracker2] = useState(true);
  const { firstDigit, secondDigit } = useEagleNumberCountingForTent(eaglesCunt);

  useEffect(() => {
    if (isLoadingTracker1 || isLoadingTracker2) return;
    setIsLoading(false);
  }, [isLoadingTracker1, isLoadingTracker2]);

  useEffect(() => {
    if (isLoading) return;
    const intervalId = setTimeout(nextSlide, slideIntervals[index]);
    return () => {
      clearTimeout(intervalId);
    };
  }, [index, isLoading]);

  const slideIntervals = {
    0: 10, // Black Image
    1: 30000, // EagleTracker 1
    2: 10, // Black Image
    3: 30000, // EagleTracker 2
    4: 10, // Black Image
    5: 30000, // EagleTracker 3
  };

  const nextSlide = () => {
    let nextIndex = 0;
    if (index < Object.keys(slideIntervals).length) nextIndex = index + 1;
    console.log(`next slide: index: ${index} setIndex: ${nextIndex}`);
    setIndex(nextIndex);
  };

  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  return (
    <div className="App">
      {isLoading ? <CircularWithValueLabel /> : null}
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        transitionTime={10}
        stopOnHover={false}
        swipeable={false}
        useKeyboardArrows={false}
        showIndicators={false}
        autoPlay={false}
        renderItem={customRenderItem}
        selectedItem={index}
      >
        <ImageSlide key="image-1" url="/black.jpg" />
        <EagleTrackerVerticalSlide
          {...props}
          isSelected={index === 1}
          firstDigit={firstDigit}
          secondDigit={secondDigit}
          setIsLoading={setIsLoadingTracker1}
        />
        <ImageSlide key="image-2" url="/black.jpg" />
        <EagleTrackerSecondVerticalSlide
          {...props}
          isSelected={index === 3}
          firstDigit={firstDigit}
          secondDigit={secondDigit}
          setIsLoading={setIsLoadingTracker2}
        />
        <ImageSlide key="image-3" url="/black.jpg" />
        <EagleTrackerBlueVerticalSlide
          {...props}
          isSelected={index === 5}
          firstDigit={firstDigit}
          secondDigit={secondDigit}
        />
      </Carousel>
    </div>
  );
};

export default TentPage;
