import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import GrovePage from './pages/grove';
import OfficesPage from './pages/offices';
import OfficesVerticalPage from './pages/offices-vertical';
import TentPage from './pages/tent';
import Hospitality17Page from './pages/hospitality-17';
import Hospitality18Page from './pages/hospitality-18';
import Hospitality17ThursdayPage from './pages/hospitality-17-thursday';
import Hospitality18ThursdayPage from './pages/hospitality-18-thursday';
import { useFetchEagleCount } from './hooks';

function App() {
  const { eagleCunt } = useFetchEagleCount(
    `${process.env.REACT_APP_EAGLE_COUNTER_URL}/api/getEagleCount`,
  );

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/offices" element={<OfficesPage eaglesCunt={eagleCunt} />} />
      <Route
        path="/offices-vertical"
        element={<OfficesVerticalPage eaglesCunt={eagleCunt} />}
      />
      <Route path="/grove" element={<GrovePage eaglesCunt={eagleCunt} />} />
      <Route path="/tent" element={<TentPage eaglesCunt={eagleCunt} />} />
      <Route
        path="/hospitality-17"
        element={<Hospitality17Page eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/hospitality-18"
        element={<Hospitality18Page eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/hospitality-17-thursday"
        element={<Hospitality17ThursdayPage eaglesCunt={eagleCunt} />}
      />
      <Route
        path="/hospitality-18-thursday"
        element={<Hospitality18ThursdayPage eaglesCunt={eagleCunt} />}
      />
    </Routes>
  );
}

export default App;
