import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import ImageSlide from '../../components/slide/ImageSlide';
import VideoSlide from '../../components/slide/VideoSlide';
import EagleTrackerSlide from '../../components/slide/EagleTrackerSlide';
import { useEagleNumberCountingForGrove } from '../../hooks';
import CircularWithValueLabel from '../../components/circular-progress';

const Hospitality17Page = (props) => {
  const { eaglesCunt } = props;
  const [isEagleTrackerLoading, setIsEagleTrackerLoading] = useState(true);
  const [isFirstSlideLoading, setIsFirstSlideLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const { firstDigit, secondDigit } = useEagleNumberCountingForGrove(
    eaglesCunt,
    isSelected,
  );

  useEffect(() => {
    if (!isEagleTrackerLoading && !isFirstSlideLoading) {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isEagleTrackerLoading, isFirstSlideLoading]);

  useEffect(() => {
    if (isLoading) return;

    const timeoutId = setTimeout(() => {
      setIndex((index) => {
        let nextIndex = 0;
        if (index < Object.keys(slideIntervals).length) nextIndex = index + 1;
        console.log(`Active slide: ${nextIndex}`);
        return nextIndex;
      });
    }, slideIntervals[index]);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [index, isLoading]);

  useEffect(() => {
    if (isLoading) return;

    setIsSelected(index === 5);
  }, [index, isLoading]);

  const slideIntervals = {
    0: 1000, // Black Image
    1: 65000, // Grit to Vision Video
    2: 50, // Black Image
    3: 30000, // Justin Rose still image
    4: 50, // Black Image
    5: 30000, // Eagle Tracker screen 1
  };

  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  return (
    <div className="App">
      {isLoading ? <CircularWithValueLabel /> : null}
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        transitionTime={3}
        stopOnHover={false}
        swipeable={false}
        useKeyboardArrows={false}
        showIndicators={false}
        autoPlay={false}
        renderItem={customRenderItem}
        selectedItem={index}
      >
        <ImageSlide key="image-10" url="/black.jpg" />
        <VideoSlide
          playing={index === 1}
          key="vide-1"
          setIsLoading={setIsFirstSlideLoading}
          url="https://bitworthy-f7dec4aehddqcnag.z01.azurefd.net/tma-mstpc/grit.mp4"
        />
        <ImageSlide key="image-11" url="/black.jpg" />
        <ImageSlide key="image-1" url="/horizontal/grove_static_slide_2.jpg" />
        <ImageSlide key="image-14" url="/black.jpg" />
        <EagleTrackerSlide
          {...props}
          isSelected={index === 5}
          firstDigit={firstDigit}
          secondDigit={secondDigit}
          setIsLoading={setIsEagleTrackerLoading}
        />
      </Carousel>
    </div>
  );
};

export default Hospitality17Page;
