import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

const EagleNumber = (props) => {
  const { firstDigit, secondDigit, styles, setIsLoadingDigits } = props;
  const [isReady, setIsReady] = useState(false);
  const [loadedVideos, setLoadedVideos] = useState([]);

  useEffect(() => {
    const timeoutlId = setTimeout(() => handleReady(11), 5000);
    return () => {
      clearTimeout(timeoutlId);
    };
  }, []);

  useEffect(() => {
    const timeoutlId = setTimeout(() => setIsReady(true), 2500);
    return () => {
      clearTimeout(timeoutlId);
    };
  }, []);

  useEffect(() => {
    if (!isReady) return;
    if (firstDigit === 9) {
      firstDigitPlayers[0]?.seekTo(0, 'seconds');
    } else {
      firstDigitPlayers[firstDigit + 1]?.seekTo(0, 'seconds');
    }
  }, [firstDigit, isReady]);

  useEffect(() => {
    if (!isReady) return;
    if (secondDigit === 9) {
      secondDigitPlayers[0]?.seekTo(0, 'seconds');
    } else {
      secondDigitPlayers[secondDigit + 1]?.seekTo(0, 'seconds');
    }
  }, [secondDigit, isReady]);

  useEffect(() => {
    const set = new Set(loadedVideos);
    if (setIsLoadingDigits && set.size === 11) {
      setIsLoadingDigits(false);
    }
  }, [loadedVideos]);

  const firstDigitPlayers = [];
  const secondDigitPlayers = [];
  const numberMapper = [
    '9-0',
    '0-1',
    '1-2',
    '2-3',
    '3-4',
    '4-5',
    '5-6',
    '6-7',
    '7-8',
    '8-9',
  ];

  const handleReady = (index) => {
    setLoadedVideos((prevUrls) => [...prevUrls, index]);
  };

  const firstDigits = () => {
    return numberMapper.map((number, index) => {
      return (
        <div
          key={`firstDigit_${index}`}
          style={{
            ...styles.firstDigit,
            display: isReady && index === firstDigit ? 'block' : 'none',
          }}
        >
          <ReactPlayer
            ref={(p) => {
              firstDigitPlayers[index] = p;
            }}
            url={`/numbers/${number}.webm`}
            muted={true}
            playing={index === firstDigit}
            width={styles.digitSize.width}
            height={styles.digitSize.height}
            id={index}
            onReady={() => handleReady(index)}
          />
        </div>
      );
    });
  };

  const secondDigits = () => {
    return numberMapper.map((number, index) => {
      return (
        <div
          key={`secondDigit_${index}`}
          style={{
            ...styles.secondDigit,
            display: isReady && index === secondDigit ? 'block' : 'none',
          }}
        >
          <ReactPlayer
            ref={(p) => {
              secondDigitPlayers[index] = p;
            }}
            url={`/numbers/${number}.webm`}
            muted={true}
            playing={index === secondDigit}
            width={styles.digitSize.width}
            height={styles.digitSize.height}
            id={index}
          />
        </div>
      );
    });
  };

  return (
    <div>
      {firstDigits()}
      {secondDigits()}
    </div>
  );
};

export default EagleNumber;
