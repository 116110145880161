import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './slide.css';
import EagleNumber from '../eagle-number';

const styles = {
  slide: {
    width: '1080px',
    height: '1920px',
  },
  countWithUs: {
    position: 'absolute',
    top: '240px',
    left: '0px',
  },
  eagleTrackerText: {
    position: 'absolute',
    top: '1120px',
    left: '0px',
  },
  logo: {
    position: 'absolute',
    top: '1650px',
    left: '0px',
  },
  eagleNumber: {
    firstDigit: {
      position: 'absolute',
      top: '260px',
      left: '-220px',
    },
    secondDigit: {
      position: 'absolute',
      top: '260px',
      left: '270px',
    },
    digitSize: {
      width: '1050px',
      height: '1050px',
    },
  },
};

const EagleTrackerSecondVerticalSlide = (props) => {
  const { eaglesCunt, setIsLoading, isSelected = true } = props;
  let player;

  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (!isSelected) {
      setPlaying(false);
      player?.seekTo(0, 'seconds');
      return;
    }
    const timeoutlId = setTimeout(() => {
      setPlaying(true);
    }, 500);
    return () => {
      clearTimeout(timeoutlId);
    };
  }, [isSelected, eaglesCunt]);

  return (
    <div key={101}>
      <div
        style={{
          ...styles.countWithUs,
        }}
      >
        <img
          src={'/vertical/tracker_1_2_header_vertical.png'}
          alt={'text_headline'}
        />
      </div>
      <div
        style={{
          ...styles.eagleTrackerText,
        }}
      >
        <img
          src={'/vertical/tracker_2_label_vertical.png'}
          alt={'text_headline'}
        />
      </div>
      <div
        style={{
          ...styles.logo,
        }}
      >
        <img
          src={'/vertical/tracker_1_2_footer_vertical.png'}
          alt={'text_headline'}
        />
      </div>
      <ReactPlayer
        ref={(p) => {
          player = p;
        }}
        className="eagle-video"
        url="/vertical/0070-0200_vertical.mp4"
        muted={true}
        playing={playing}
        onEnded={() => setPlaying(false)}
        width={styles.slide.width}
        height={styles.slide.height}
        onReady={() => setIsLoading(false)}
      />

      <div>
        <EagleNumber {...props} styles={styles.eagleNumber} />
      </div>
    </div>
  );
};

export default EagleTrackerSecondVerticalSlide;
