import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import EagleTrackerVerticalSlide from '../../components/slide/EagleTrackerVerticalSlide';
import { useEagleNumberCountingForTent } from '../../hooks';
import CircularWithValueLabel from '../../components/circular-progress';

const OfficesVerticalPage = (props) => {
  const { eaglesCunt } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const { firstDigit, secondDigit } = useEagleNumberCountingForTent(eaglesCunt);

  useEffect(() => {
    if (isLoading) return;
    if (isSelected) {
      const timeoutId = setTimeout(() => setIsSelected(false), 300000);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      const timeoutId = setTimeout(() => setIsSelected(true), 500);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isLoading, isSelected]);

  return (
    <div className="App">
      {isLoading ? <CircularWithValueLabel /> : null}
      <EagleTrackerVerticalSlide
        {...props}
        firstDigit={firstDigit}
        isSelected={isSelected}
        secondDigit={secondDigit}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

export default OfficesVerticalPage;
