import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import EagleTrackerSlide from '../../components/slide/EagleTrackerSlide';
import CircularWithValueLabel from '../../components/circular-progress';
import { useEagleNumberForOffice } from '../../hooks';

const OfficesPage = (props) => {
  const { eaglesCunt } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const { firstDigit, secondDigit } = useEagleNumberForOffice(
    eaglesCunt,
    !isLoading,
  );

  useEffect(() => {
    if (isLoading) return;
    if (isSelected) {
      const timeoutId = setTimeout(() => setIsSelected(false), 300000);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      const timeoutId = setTimeout(() => setIsSelected(true), 500);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isLoading, isSelected]);

  return (
    <div className="App">
      {isLoading ? <CircularWithValueLabel /> : null}
      <EagleTrackerSlide
        style={{ display: 'block' }}
        {...props}
        setIsLoading={setIsLoading}
        isSelected={isSelected}
        firstDigit={firstDigit}
        secondDigit={secondDigit}
      />
    </div>
  );
};

export default OfficesPage;
