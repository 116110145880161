export const getFirstAndSecondDigits = (number) => {
  let firstDigit = 0,
    secondDigit = 0;
  if (number > 9) {
    firstDigit = parseInt(number.toString()[0]);
    secondDigit = parseInt(number.toString()[1]);
  } else if (number > 0) {
    secondDigit = parseInt(number.toString()[0]);
  }
  return { firstDigit, secondDigit };
};
