import { useState, useEffect } from 'react';
import { getFirstAndSecondDigits } from '../utils';

export const useFetchEagleCount = (url) => {
  const [eagleCunt, setEagleCunt] = useState(0);

  useEffect(() => {
    const fetchApi = () => {
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Error: ' + response.statusText);
          }
        })
        .then((data) => {
          console.log('API response:', JSON.stringify(data));
          setEagleCunt(data.eagle_count);
        })
        .catch((err) => {
          console.log('Error: ', err);
        });
    };

    // Call fetchApi immediately and then every 5 seconds
    fetchApi();
    const intervalId = setInterval(fetchApi, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [url]);

  return { eagleCunt };
};

export const useEagleNumberForOffice = (eagleCunt, isSelected) => {
  const [increment, setIncrement] = useState(0);
  const [secondDigit, setSecondDigit] = useState();
  const [firstDigit, setFirstDigit] = useState();

  useEffect(() => {
    const intervalId = setTimeout(() => {
      const eagleCountReduced = eagleCunt - 5 < 0 ? 0 : eagleCunt - 5;
      setIncrement(eagleCountReduced);
      const number = getFirstAndSecondDigits(eagleCountReduced);
      setFirstDigit(number.firstDigit);
      setSecondDigit(number.secondDigit);
    }, 2000);
    return () => {
      clearTimeout(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isSelected || eagleCunt === 0) {
      const intervalId = setTimeout(() => {
        if (increment < eagleCunt) {
          const nextNumber = increment + 1;
          const number = getFirstAndSecondDigits(nextNumber);
          if (firstDigit !== number.firstDigit)
            setFirstDigit(number.firstDigit);
          if (secondDigit !== number.secondDigit)
            setSecondDigit(number.secondDigit);
          setIncrement(nextNumber);
          console.log(
            `startCounting: firstDigit ${number.firstDigit} secondDigit ${number.secondDigit} nextNumber ${nextNumber}`,
          );
        }
      }, 2500);
      return () => {
        clearTimeout(intervalId);
      };
    }
  }, [eagleCunt, isSelected, increment]);

  return { firstDigit, secondDigit, increment, setIncrement };
};

export const useEagleNumberCountingForGrove = (eagleCunt, isSelected) => {
  const [increment, setIncrement] = useState(-1);
  const [secondDigit, setSecondDigit] = useState();
  const [firstDigit, setFirstDigit] = useState();

  useEffect(() => {
    if (!isSelected) {
      const intervalId = setTimeout(() => {
        const eagleCountReduced = eagleCunt - 5 < 0 ? 0 : eagleCunt - 5;
        const number = getFirstAndSecondDigits(eagleCountReduced);
        setFirstDigit(number.firstDigit);
        setSecondDigit(number.secondDigit);
        setIncrement(eagleCountReduced);
        console.log(
          `Recounting: firstDigit ${number.firstDigit} secondDigit ${number.secondDigit} nextNumber ${eagleCountReduced}`,
        );
      }, 500);
      return () => {
        clearTimeout(intervalId);
      };
    } else {
      const intervalId = setTimeout(() => {
        if (increment < eagleCunt) {
          const nextNumber = increment + 1;
          const number = getFirstAndSecondDigits(nextNumber);
          setFirstDigit(number.firstDigit);
          setSecondDigit(number.secondDigit);
          setIncrement(nextNumber);
          console.log(
            `Counting: firstDigit ${number.firstDigit} secondDigit ${number.secondDigit} nextNumber ${nextNumber}`,
          );
        }
      }, 2500);
      return () => {
        clearTimeout(intervalId);
      };
    }
  }, [eagleCunt, isSelected, increment]);

  return { firstDigit, secondDigit };
};

export const useEagleNumberCountingForTent = (eagleCunt) => {
  const [increment, setIncrement] = useState(-1);
  const [secondDigit, setSecondDigit] = useState();
  const [firstDigit, setFirstDigit] = useState();

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (increment === -1 && eagleCunt >= 0) {
        const number = getFirstAndSecondDigits(eagleCunt);
        setFirstDigit(number.firstDigit);
        setSecondDigit(number.secondDigit);
        setIncrement(eagleCunt);
      } else if (increment < eagleCunt) {
        const nextNumber = increment + 1;
        const number = getFirstAndSecondDigits(nextNumber);
        setFirstDigit(number.firstDigit);
        setSecondDigit(number.secondDigit);
        setIncrement(nextNumber);
        console.log(
          `Counting: firstDigit ${number.firstDigit} secondDigit ${number.secondDigit} nextNumber ${nextNumber}`,
        );
      }
    }, 2500);
    return () => {
      clearTimeout(intervalId);
    };
  }, [eagleCunt, increment]);

  return { firstDigit, secondDigit };
};
